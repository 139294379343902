
import { defineComponent, onMounted } from "vue";
import { setCurrentPageTitle } from "@/presentation/helper/Breadcrumb";
import { ErrorMessage, Field, Form } from "vee-validate";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "interview",
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  setup() {
    const { t, te } = useI18n();

    onMounted(() => {
      setCurrentPageTitle(t("accountStatus.pageTitle"));
    });

    return {};
  },
});
