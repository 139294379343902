<template>
  <!--begin::Row-->
  <div class="row g-5 g-xl-8">
    <div class="col-xl-12">
      <div class="card mb-xl-8">
        <!--begin::Body-->
        <div class="card-body">
          <!--begin::Heading-->
          <div class="text-center">
            <!--begin::Title-->
            <div class="card-label fw-bolder fs-3 mb-3">
              {{ $t("accountStatus.title") }}
            </div>
            <!--end::Title-->
          </div>
          <!--end::Heading-->
          <!--begin::Form-->
          <Form id="kt_account_profile_details_form" class="form">
            <!--begin::Card body-->
            <div class="card-body p-9 ps-6 pb-1">
              <div class="row mb-2">
                <div class="col-md-6">
                  <!--begin::Input group-->
                  <div class="fv-row mb-10">
                    <!--begin::Label-->
                    <label class="form-label fs-6 fw-bolder text-dark">{{
                      $t("accountStatus.companyName")
                    }}</label>
                    <!--end::Label-->

                    <!--begin::Input-->
                    <Field
                      class="form-control form-control-lg form-control-solid"
                      type="text"
                      name="surname"
                      autocomplete="off"
                      :placeholder="$t('accountStatus.enterCompanyName')"
                      value="Ogzatech"
                      readonly
                    />
                    <!--end::Input-->
                  </div>
                  <!--end::Input group-->
                </div>
                <div class="col-md-6">
                  <!--begin::Input group-->
                  <div class="fv-row mb-10">
                    <!--begin::Label-->
                    <label class="form-label fs-6 fw-bolder text-dark">{{
                      $t("accountStatus.email")
                    }}</label>
                    <!--end::Label-->

                    <!--begin::Input-->
                    <Field
                      class="form-control form-control-lg form-control-solid"
                      type="email"
                      name="email"
                      autocomplete="off"
                      :placeholder="$t('accountStatus.enterEmail')"
                      value="destek@ogzatech.com"
                      readonly
                    />
                    <!--end::Input-->
                  </div>
                  <!--end::Input group-->
                </div>
              </div>
              <div class="row mb-0">
                <div class="col-md-4">
                  <!--begin::Input group-->
                  <div class="fv-row mb-10">
                    <!--begin::Label-->
                    <label class="form-label fs-6 fw-bolder text-dark">{{
                      $t("accountStatus.accountType")
                    }}</label>
                    <!--end::Label-->

                    <!--begin::Input-->
                    <Field
                      class="form-control form-control-lg form-control-solid"
                      type="text"
                      name="accountType"
                      autocomplete="off"
                      value="Ücretsiz Hesap"
                      readonly
                    />
                  </div>
                  <!--end::Input group-->
                </div>
                <div class="col-md-4">
                  <!--begin::Input group-->
                  <div class="fv-row mb-10">
                    <!--begin::Label-->
                    <label class="form-label fs-6 fw-bolder text-dark">{{
                      $t("accountStatus.activeModalCount")
                    }}</label>
                    <!--end::Label-->

                    <!--begin::Input-->
                    <Field
                      class="form-control form-control-lg form-control-solid"
                      type="number"
                      name="moduleCount"
                      autocomplete="off"
                      placeholder="0"
                      value="5"
                      readonly
                    />
                    <!--end::Input-->
                  </div>
                  <!--end::Input group-->
                </div>
                <div class="col-md-4">
                  <!--begin::Input group-->
                  <div class="fv-row mb-10">
                    <!--begin::Label-->
                    <label class="form-label fs-6 fw-bolder text-dark">{{
                      $t("accountStatus.processCountofPeriod")
                    }}</label>
                    <!--end::Label-->

                    <!--begin::Input-->
                    <Field
                      class="form-control form-control-lg form-control-solid"
                      type="number"
                      name="count"
                      autocomplete="off"
                      placeholder="0"
                      value="434"
                      readonly
                    />
                    <!--end::Input-->
                  </div>
                  <!--end::Input group-->
                </div>
              </div>
            </div>
            <!--end::Card body-->
          </Form>
          <!--end::Form-->
        </div>
        <!--end::Body-->
      </div>
    </div>
  </div>
  <!--end::Row-->

  <!--begin::Row-->
  <div class="row g-5 g-xl-8">
    <div class="col-xl-12">
      <div class="card mb-5 mb-xl-8">
        <!--begin::Body-->
        <div class="card-body pt-5">
          <!--begin::Heading-->
          <div class="text-center">
            <!--begin::Title-->
            <div class="card-label fw-bolder fs-3 mb-4 mt-2">
              {{ $t("accountStatus.purchaseHistory") }}
            </div>
            <!--end::Title-->
          </div>
          <!--end::Heading-->
          <div class="dataTables_wrapper dt-bootstrap4 no-footer">
            <div class="table-responsive">
              <table
                class="table align-middle table-row-dashed fs-6 gy-5 no-footer"
                id="kt_customers_table"
                role="grid"
              >
                <!--begin::Table-->
                <thead>
                  <tr
                    class="
                      text-start text-gray-400
                      fw-bolder
                      fs-7
                      text-uppercase
                      gs-0
                      text-center
                    "
                    role="row"
                  >
                    <td width="25%">
                      {{ $t("accountStatus.receivedModule") }}
                    </td>
                    <td width="30%">{{ $t("accountStatus.price") }}</td>
                    <td width="30%">{{ $t("accountStatus.procesDate") }}</td>
                  </tr>
                </thead>
                <tbody class="fw-bold text-gray-600 text-center">
                  <!--
                  <tr class="odd">
                    <td>Mülakat Uygulaması</td>
                    <td>79,99 TL</td>
                    <td>20.05.2024</td>
                  </tr>
                  <tr class="odd">
                    <td>Anketör Uygulaması</td>
                    <td>59,99 TL</td>
                    <td>14.05.2024</td>
                  </tr>-->

                  <tr class="odd">
                    <td>AI Otel Asistanı</td>
                    <td>Ücretsiz</td>
                    <td>30.05.2024</td>
                  </tr>
                </tbody>
                <!--end::Table-->
              </table>
            </div>
          </div>
        </div>
        <!--end::Body-->
      </div>
    </div>
  </div>
  <!--end::Row-->
</template>

<script lang="ts">
import { defineComponent, onMounted } from "vue";
import { setCurrentPageTitle } from "@/presentation/helper/Breadcrumb";
import { ErrorMessage, Field, Form } from "vee-validate";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "interview",
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  setup() {
    const { t, te } = useI18n();

    onMounted(() => {
      setCurrentPageTitle(t("accountStatus.pageTitle"));
    });

    return {};
  },
});
</script>
